<template>
  <div class="bg-default">
    <!-- <div>主页轮播图</div> -->
    <div class="wrap-swipeout"></div>
    <van-row type="flex" justify="center" class="wrap-swipe">
      <van-col span="23">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item>
            <img
              class="banner"
              src="https://sms-1300492491.cos.ap-beijing.myqcloud.com/news/banner01.png"
            />
          </van-swipe-item>
          <van-swipe-item>
            <img
              class="banner"
              src="https://sms-1300492491.cos.ap-beijing.myqcloud.com/news/banner02.png"
            />
          </van-swipe-item>
          <van-swipe-item>
            <img
              class="banner"
              src="https://sms-1300492491.cos.ap-beijing.myqcloud.com/news/banner03.png"
            />
          </van-swipe-item>
        </van-swipe>
      </van-col>
    </van-row>
    <!-- <div>主页功能区</div> -->
    <van-row type="flex" justify="center">
      <van-col span="23" class="wrap-function">
        <van-row type="flex" justify="space-around">
          <van-col @click="todaypay">
            <img
              class="fn"
              src="https://sms-1300492491.cos.ap-beijing.myqcloud.com/icon/icon01.png"
            />
            <div class="fn-text">乐捐</div>
          </van-col>
          <van-col @click="tolamp">
            <img
              class="fn"
              src="https://sms-1300492491.cos.ap-beijing.myqcloud.com/icon/icon02.png"
            />
            <div class="fn-text">供灯</div>
          </van-col>
          <van-col @click="toregister">
            <img
              class="fn"
              src="https://sms-1300492491.cos.ap-beijing.myqcloud.com/icon/icon03.png"
            />
            <div class="fn-text">佛事</div>
          </van-col>
          <van-col @click="togolive">
            <img
              class="fn"
              src="https://sms-1300492491.cos.ap-beijing.myqcloud.com/icon/icon04.png"
            />
            <div class="fn-text">放生</div>
          </van-col>
        </van-row>
        <div class="fn-middle"></div>
        <!-- <div>主页功能区下半区</div> -->
        <van-row type="flex" justify="space-around">
          <van-col @click="tobrand">
            <img
              class="fn"
              src="https://sms-1300492491.cos.ap-beijing.myqcloud.com/icon/icon05.png"
            />
            <div class="fn-text">牌位</div>
          </van-col>
          <van-col @click="tooffers">
            <img
              class="fn"
              src="https://sms-1300492491.cos.ap-beijing.myqcloud.com/icon/icon06.png"
            />
            <div class="fn-text">供养</div>
          </van-col>
          <van-col @click="tooffers">
            <img
              class="fn"
              src="https://sms-1300492491.cos.ap-beijing.myqcloud.com/icon/icon07.png"
            />
            <div class="fn-text">助印</div>
          </van-col>
          <van-col @click="tooffers">
            <img
              class="fn"
              src="https://sms-1300492491.cos.ap-beijing.myqcloud.com/icon/icon08.png"
            />
            <div class="fn-text">供斋</div>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
    <!-- <div>石门热点</div> -->
    <van-row type="flex" justify="center">
      <van-col class="news-hot" span="23">石门热点</van-col>
    </van-row>
    <!-- <div>热点列表</div> -->
    <van-row type="flex" justify="center">
      <van-col span="23">
        <div class="news-list" v-for="(item,index) in list" :key="index" @click="todetail(item.id)">
          <van-row type="flex" justify="space-between">
            <van-col class="news-title" span="14">{{item.title}}</van-col>
            <van-col span="8">
              <img class="news-image" :src="item.headimgurl" />
            </van-col>
          </van-row>
          <div class="news-author">
            <van-row>
              <van-col span="13">
                <van-row type="flex" justify="space-between">
                  <van-col>{{item.author}}</van-col>
                  <van-col>{{item.createtime}}</van-col>
                </van-row>
              </van-col>
            </van-row>
          </div>
        </div>
      </van-col>
    </van-row>

    <!-- <div>底部地基</div> -->
    <div class="wrap-bottom"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    getlist() {
      this.$api.article.getarticlelist(1, 1).then((res) => {
        this.list = res.data.data.list;
        console.log(res);
      });
    },
    todetail(id) {
      this.$router.push({
        path: "/detail",
        query: {
          id: id,
        },
      });
    },
    tolamp() {
      this.$router.push({
        path: "/lamp",
      });
    },
    toregister() {
      this.$router.push({
        path: "/register",
      });
    },
    togolive() {
      this.$router.push({
        path: "/golive",
      });
    },
    tobrand() {
      this.$router.push({
        path: "/brand",
      });
    },
    tooffers() {
      this.$router.push({
        path: "/offers",
      });
    },
    todaypay() {
      this.$router.push({
        path: "/daypay",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
//新闻
.news {
  &-hot {
    margin: 0.8rem 0rem 0.8rem 0rem;
    font-size: 18px;
    font-weight: bold;
    color: $text-black;
  }
}
.wrap {
  &-swipeout {
    background-color: $bg-banner;
    height: 8rem;
  }
  &-swipe {
    margin-top: -7.2rem;
    border: 0.4rem;
  }
  &-function {
    border-radius: 0.4rem;
    background-color: $bg-white;
    margin-top: 0.8rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
}
.fn {
  background-size: 100%;
  background-repeat: no-repeat;
  width: 3.2rem;
  height: 3.2rem;
  &-text {
    font-size: 16px;
    text-align: center;
  }
  &-middle {
    height: 0.4rem;
  }
}
</style>